import React from "react";

import notFound from '../../assets/img/404.png';
import {getBookDetails} from "../../redux/book_details/actions";
import {connect} from "react-redux";
import {addItemToCart} from "../../redux/cart/actions";
import {numberWithCommas} from "../../helpers/helper_functions";
import {RATES} from "../../values/globals";

const $ = window.$;

const mapStateToProps = state => (state.cartItemsState);

const mapDispatchToProps = dispatch => ({
    addItemToCart: (item) => dispatch(addItemToCart(item)),
    getBookDetails: (id) => dispatch(getBookDetails(id))
});

class SingleBook extends React.Component {

    state = {};

    componentDidMount() {
        this.checkRates();
    }

    checkRates = () => {
        setTimeout(() => {
            const rates = localStorage.getItem(RATES);
            if (rates)
                this.setState({
                    rate: rates
                })
            else
                this.checkRates();
        }, 500)
    }

    handleOpenBookDetailsModal = () => {
        const {book, getBookDetails} = this.props;
        getBookDetails(book)
        $('#bookDetailsModal').modal('show');
    }

    handleAddItemToCart = e => {
        e.stopPropagation();
        const {addItemToCart, book} = this.props;
        addItemToCart(book);
    }

    render() {
        const {book, loadingAdding, item} = this.props;
        const {rate} = this.state;
        let loading = loadingAdding && item?.id === book?.id;
        let price = "-";
        if (rate)
            price = (book?.price / rate)?.toFixed(2);
        return (
            <div onClick={this.handleOpenBookDetailsModal}
                 className="col text-center p-5 rounded book-container d-flex flex-column justify-content-between">
                <div style={{height: "30vh"}}>
                    <img style={{width: "100%", height: "100%", objectFit: "contain"}}
                         src={book.path || notFound}
                         alt={"book placeholder"}/>
                </div>
                <h4 className="my-3 font-weight-normal">
                    {book.title}
                </h4>

                <p className="font-weight-bold">
                    {/*KES {numberWithCommas(book.price ?? "")}*/}
                    ${numberWithCommas(price || "-")}
                </p>

                <button disabled={loading} onClick={this.handleAddItemToCart} className="btn btn-dark">
                    {loading ? "Adding..." : "Purchase"}
                </button>
            </div>
        )

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleBook);
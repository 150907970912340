import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import {makeRequest} from "../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST, PATCH_REQUEST, POST_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";

export const FETCH_CART_ITEMS = "fetch_cart_items";

export const LOADING_CART = "loading_cart";
export const LOADING_ADDING_CART = "loading_adding_cart";
export const LOADING_DELETING_CART = "loading_deleting_cart";

/*const getCart = () => JSON.parse(localStorage.getItem("cart") || "[]");

const saveCart = (cart) => localStorage.setItem("cart", JSON.stringify(cart));*/

export const getCartItems = () => {
    return dispatch => {
        dispatch({
            type: LOADING_CART,
            cart: []
        });

        makeRequest(GET_REQUEST, endpoints.current_order, {}, response => {
            dispatch({
                type: FETCH_CART_ITEMS,
                cart: response.data
            })
        }, error => {
            if (error?.response?.status !== 404 && error?.response?.status !== 401)
                showAlert('error', 'Error', parseErrorResponse(error))
            dispatch({
                type: FETCH_CART_ITEMS,
                cart: []
            })
        })
    }
}

export const changeItemQuantity = (item, quantity) => dispatch => {
    if (quantity < 1) return;
    dispatch({
        type: LOADING_ADDING_CART,
        item: item
    });

    makeRequest(PATCH_REQUEST, endpoints.orders, {quantity: quantity, id: item.id},
        response => {
            dispatch({
                type: FETCH_CART_ITEMS,
                cart: response.data
            })
            showAlert('success', 'Item Quantity', 'Item quantity changed successfully!');
        }, error => {
            if (error?.response?.status !== 404)
                showAlert('error', 'Error', parseErrorResponse(error))
            dispatch({
                type: FETCH_CART_ITEMS,
                cart: []
            })
        })
}

export const addItemToCart = (item) => dispatch => {
    dispatch({
        type: LOADING_ADDING_CART,
        item: item
    });
    makeRequest(POST_REQUEST, endpoints.orders, {quantity: 1, id: item.id},
        response => {
            dispatch({
                type: FETCH_CART_ITEMS,
                cart: response.data
            })
            showAlert('success', 'Item Quantity', 'Item added to cart successfully!');
        }, error => {
            if (error?.response?.status !== 404)
                showAlert('error', 'Error', parseErrorResponse(error))
            dispatch({
                type: FETCH_CART_ITEMS,
                cart: []
            })
        });

}

export const removeItemFromCart = (item) => dispatch => {
    dispatch({
        type: LOADING_DELETING_CART,
        item: item
    });

    makeRequest(DELETE_REQUEST, `${endpoints.orders}?id=${item.id}`, {id: item.id},
        response => {
            dispatch({
                type: FETCH_CART_ITEMS,
                cart: response.data
            })
            showAlert('success', 'Item Deletion', 'Item deleted from cart successfully!');
        }, error => {
            if (error?.response?.status !== 404) {
                showAlert('error', 'Error', parseErrorResponse(error))
                dispatch({
                    type: FETCH_CART_ITEMS,
                })
            }

        });
}
import React from 'react';
import EditPasswordModal from "./edit_password_modal";

function InformationEdit() {
    return (
        <div className="row mx-auto">
            <EditPasswordModal/>
            <div className="col-md-4">
                <h5>Edit Info</h5>
            </div>
            <div className="col-md-8">
                <button data-toggle="modal"
                        data-target="#editPasswordModal"
                        className="btn btn-sm btn-info w-100">
                    Change Password
                </button>
            </div>
        </div>
    );
}


export default InformationEdit;
import {FETCH_BOOK_DETAILS, LOADING_BOOK_DETAILS} from "./actions";

const bookDetailsState = {
    book: null,
    loading: false
};

const reducer = (state = bookDetailsState, action) => {
    switch (action.type) {
        case FETCH_BOOK_DETAILS:
            return {...state, book: action.book, loading: false};
        case LOADING_BOOK_DETAILS:
            return {...state, loading: true};
        default:
            return state;
    }
};

export default reducer;
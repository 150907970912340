import {FETCH_BOOK_TAGS, LOADING_BOOK_TAGS} from "./actions";

const bookTagsState = {
    book_tags: null,
    loading: false
};

const reducer = (state = bookTagsState, action) => {
    switch (action.type) {
        case FETCH_BOOK_TAGS:
            return {...state, book_tags: action.book_tags, loading: false};
        case LOADING_BOOK_TAGS:
            return {...state, loading: true};
        default:
            return state;
    }
};

export default reducer;
import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {isUserLoggedIn} from "../../helpers/login";
import logo from '../../assets/img/logo.png';
import {getBookTags} from "../../redux/book_tags/actions";
import CircularProgress from "@material-ui/core/CircularProgress";

const $ = window.$;
const mapStateToProps = state => ({...state.cartItemsState, ...state.bookTagsState});

const mapDispatchToProps = dispatch => ({
    getBookTags: () => dispatch(getBookTags())
});

class Navbar extends React.Component {
    componentDidMount() {
        const {book_tags, getBookTags} = this.props;
        if (book_tags == null)
            getBookTags()
    }

    render() {
        //   const user = getUserObject();
        let {cart, book_tags, loading} = this.props;
        if (book_tags == null) book_tags = [];
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-white p-3 align-items-center">
                <Link className="navbar-brand" to="/">
                    <img src={logo} style={{width: "200px", height: "40px"}} alt="logo"/>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto text-uppercase">
                        <NavItem to={"/"} text={"All Products"}/>
                        {/*<li className="nav-item dropdown active a-border">
                        <a className="nav-link dropdown-toggle text-dark px-0" href="#resources" id="navbarDropdown"
                           role="button"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Digital Resources
                        </a>
                        <div className="dropdown-menu dropdown-menu-right mb-3"
                             aria-labelledby="navbarDropdown">
                            <NavItemCollapse text={"Leadership Management"}>
                                <NavItemDropDown to={"/books"} text={"Ebooks"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Webinar"}/>
                            </NavItemCollapse>
                            <NavItemCollapse text={"Christian Ministry"}>
                                <NavItemDropDown to={"/books"} text={"Ebooks"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Webinar"}/>
                            </NavItemCollapse>
                            <NavItemCollapse text={"Personal Development"}>
                                <NavItemDropDown to={"/books"} text={"Ebooks"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Webinar"}/>
                            </NavItemCollapse>
                            <NavItemCollapse text={"Marriage & Development"}>
                                <NavItemDropDown to={"/books"} text={"Ebooks"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Webinar"}/>
                            </NavItemCollapse>
                            <NavItemCollapse text={"Critical Thinking & "}>
                                <NavItemDropDown to={"/books"} text={"Ebooks"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Webinar"}/>
                            </NavItemCollapse>
                        </div>
                    </li>*/}
                        <li className="nav-item dropdown active a-border">
                            <a className="nav-link dropdown-toggle text-dark px-0" href="#resources" id="navbarDropdown"
                               role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Digital Resources
                            </a>
                            <div className="dropdown-menu dropdown-menu-right mb-3"
                                 aria-labelledby="navbarDropdown">
                                {
                                    loading ?
                                        <div className="text-center p-3">
                                            <CircularProgress/>
                                        </div> :
                                        book_tags.map(book_tag =>
                                            <NavItemDropDownWithChildren key={book_tag.id} text={book_tag.name}>
                                                <NavItemDropDown to={`/books?tag=${book_tag.slug}`} text={"Ebooks"}/>
                                                <NavItemDropDown to={`/podcasts?tag=${book_tag.slug}`}
                                                                 text={"Podcasts"}/>
                                                <NavItemDropDown to={`/webinar?tag=${book_tag.slug}`}
                                                                 text={"Online Courses"}/>
                                            </NavItemDropDownWithChildren>
                                        )
                                }
                                {/* <NavItemDropDownWithChildren text={"Christian Ministry"}>
                                <NavItemDropDown to={"/books"} text={"Ebooks"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Webinar"}/>
                            </NavItemDropDownWithChildren>
                            <NavItemDropDownWithChildren text={"Personal Development"}>
                                <NavItemDropDown to={"/books"} text={"Ebooks"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Webinar"}/>
                            </NavItemDropDownWithChildren>
                            <NavItemDropDownWithChildren text={"Marriage & Development"}>
                                <NavItemDropDown to={"/books"} text={"Ebooks"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Webinar"}/>
                            </NavItemDropDownWithChildren>
                            <NavItemDropDownWithChildren text={"Critical Thinking & "}>
                                <NavItemDropDown to={"/books"} text={"Ebooks"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Webinar"}/>
                            </NavItemDropDownWithChildren>*/}
                            </div>
                        </li>
                        <li className="nav-item dropdown active a-border">
                            <a className="nav-link dropdown-toggle text-dark px-0" href="#releases" id="navbarDropdown"
                               role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                New Releases
                            </a>
                            <div className="dropdown-menu dropdown-menu-right mb-3 text-center"
                                 aria-labelledby="navbarDropdown">
                                <NavItemDropDown to={"/books"} text={"E-Books"}/>
                                <NavItemDropDown to={"/podcasts"} text={"Podcasts"}/>
                                <NavItemDropDown to={"/webinar"} text={"Online Courses"}/>
                            </div>
                        </li>
                        <NavItem to={"/academy"} text={"Academy"}/>
                        <NavItem to={"/blog"} text={"Blog"}/>
                    </ul>
                    <div className="nav-item dropdown active a-border">
                        <a className="nav-link dropdown-toggle text-dark px-0" href="#account" id="navbarDropdown"
                           role="button"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Account
                        </a>
                        {
                            isUserLoggedIn() ?
                                <div className="dropdown-menu dropdown-menu-lg-right mb-3 text-center"
                                     aria-labelledby="navbarDropdown">
                                    <NavItemDropDown to={"/profile"} icon={"perm_identity"} text={"Profile"}/>
                                    <NavItemDropDown to={"/orders"} icon={"list"} text={"Orders"}/>
                                    <NavItemDropDown to={"/logout"} icon={"exit_to_app"} text={"Logout"}/>
                                </div>
                                :
                                <div className="dropdown-menu dropdown-menu-lg-right mb-3 text-center"
                                     aria-labelledby="navbarDropdown">
                                    <NavItemDropDown to={"/login"} icon={"login"} text={"Sign In"}/>
                                    <NavItemDropDown to={"/register"} icon={"account_circle"} text={"Sign Up"}/>
                                </div>
                        }
                    </div>
                    <span data-toggle="modal"
                          data-target="#cartItemsModal" className="a-border">
                        <i style={{fontSize: "1.2rem"}} className="icon-basket"/>
                        {cart?.items?.length ?? 0}
                    </span>
                </div>
            </nav>
        );
    }
}

function NavItem(props) {
    const {to, text} = props;
    const location = useLocation();
    const shouldBeActive = location.pathname === props.to;
    return (
        <li data-toggle="collapse" data-target=".navbar-collapse.show"
            className={`nav-item active a-border ${shouldBeActive ? "a-border-active" : ""}`}>
            <Link className="nav-link" to={to}>{text}</Link>
        </li>
    )
}


function NavItemDropDown({to, text, icon, size}) {
    const location = useLocation();
    const shouldBeActive = location.pathname.startsWith(to);
    return (
        <Link data-toggle="collapse"
              data-target=".navbar-collapse.show"
              style={{fontSize: `${size ? size : ".8rem"}`}}
              className={`nav-link d-flex align-items-center px-3 py-2 text-dark nav-item active a-border ${shouldBeActive ? "a-border-active" : ""}`}
              to={to}>
            <i className="material-icons mr-2"> {icon}</i> {text}
        </Link>
    )
}


function NavItemDropDownWithChildren({children, text, icon}) {
    return (
        <div className="btn-group dropright">
            <span onClick={handleDropDownWithinDropDownClick} data-toggle="dropdown" data-target="#my-dropdown"
                  aria-haspopup="true" aria-expanded="false"
                  style={{fontSize: ".8rem"}}
                  className={`dropdown-toggle nav-link d-flex align-items-center px-3 py-2 text-dark nav-item active a-border`}>
                <i className="material-icons mr-2"> {icon}</i> {text}
            </span>
            {children &&
            <div className="dropdown-menu dropmenu-right">
                {children}
            </div>
            }
        </div>
    )
}

function handleDropDownWithinDropDownClick(e) {
    e.stopPropagation();
    let target = $(e.target);
    let firstDropDownMenu = target.parent("div").children(".dropdown-menu").first();


    //Hide all other elements have class show:
    $('.dropmenu-right').not(firstDropDownMenu).removeClass('show');

    //When body is clicked, also hide all elements with class show.
    $(document).on('click', () => {
        $('.dropmenu-right').removeClass('show');
    })

    if (firstDropDownMenu.hasClass('show')) {
        firstDropDownMenu.removeClass('show');
    } else {
        firstDropDownMenu.addClass('show');
    }

}

/*function NavItemCollapse({children, text, icon}) {
    const id = `col_${getRandomString(5)}`;
    return (
        <div>
            <span onClick={e => handleCollapseClick(e, id)} data-toggle="collapse"
                  data-target={`#${id}`}
                  aria-expanded="false"
                  aria-controls={`${id}`}
                  style={{fontSize: ".8rem"}}
                  className={`dropdown-toggle nav-link d-flex align-items-center px-3 py-2 text-dark nav-item active a-border`}>
                <i className="material-icons mr-2"> {icon}</i> {text}
            </span>
            {children &&
            <div className="collapse ml-2" id={id}>
                {children}
            </div>
            }
        </div>
    )
}

function handleCollapseClick(e, id) {
    e.stopPropagation();

    let firstCollapse = $(`#${id}`).first();

    //Hide all other collapsed elements: (except the navbar)
    let navbar = $(`#navbarSupportedContent`).first();
    $('.collapse').not(firstCollapse).not(navbar).collapse('hide');

    firstCollapse.collapse('toggle');

     $(document).on('click', () => {
         $('.collapse').collapse('hide');
     })
}*/


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
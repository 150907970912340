import React, {Component} from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import notFound from "../../assets/img/404.png";
import {addItemToCart} from "../../redux/cart/actions";
import {numberWithCommas} from "../../helpers/helper_functions";
import {RATES} from "../../values/globals";

const mapStateToProps = state => ({
    bookDetailsState: state.bookDetailsState,
    cartItemsState: state.cartItemsState
});

const mapDispatchToProps = dispatch => ({
    addItemToCart: (item) => dispatch(addItemToCart(item)),
})

class BookDetails extends Component {

    state = {};

    componentDidMount() {
        this.checkRates();
    }

    checkRates = () => {
        setTimeout(() => {
            const rates = localStorage.getItem(RATES);
            if (rates)
                this.setState({
                    rate: rates
                })
            else
                this.checkRates();
        }, 500)
    }

    handleAddItemToCart = e => {
        e.stopPropagation();
        const {book} = this.props.bookDetailsState;
        const {addItemToCart} = this.props;
        addItemToCart(book);
    }

    render() {
        const {book, loading} = this.props.bookDetailsState;
        const {loadingAdding, item} = this.props.cartItemsState;
        let loadingAdd = loadingAdding && item?.id === book?.id;
        const {rate} = this.state;
        let price = "-";
        if (rate)
            price = (book?.price / rate)?.toFixed(2);
        return (
            <div className="modal fade" id="bookDetailsModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Book Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body my-3">
                            {
                                loading ?
                                    <div className="text-center">
                                        <CircularProgress/>
                                    </div> :
                                    book ?
                                        <div className="col-12">
                                            <div className="row align-items-center">
                                                <div className="col-md-5 px-5">
                                                    <div style={{height: "60vh"}}>
                                                        <img style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "contain"
                                                        }}
                                                             src={book.path || notFound}
                                                             alt={"book placeholder"}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 px-5">
                                                    <h4 className="mb-3"> {book.title}</h4>
                                                    <p className="mb-3"> {book.description}</p>

                                                    <p className="font-weight-bold mb-3">
                                                        {/* KES {numberWithCommas(book.price)}*/}
                                                        ${numberWithCommas(price || "-")}
                                                    </p>

                                                    <p className="small mb-3">
                                                        <b>Availability: </b>In Stock
                                                    </p>

                                                    <p>
                                                        {
                                                            book.tags.map(tag => <span key={tag.id}
                                                                                       className="badge badge-secondary ml-1">{tag.name}</span>)
                                                        }
                                                    </p>
                                                    <button disabled={loadingAdd} onClick={this.handleAddItemToCart}
                                                            className="btn btn-dark">
                                                        {loadingAdd ? "Adding..." : "Purchase"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="text-center">
                                            <p>Book could not be loaded.</p>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookDetails);
import {FETCH_CART_ITEMS, LOADING_ADDING_CART, LOADING_CART, LOADING_DELETING_CART} from "./actions";

const cartItemsState = {
    cart: null,
    loading: false
};

const reducer = (state = cartItemsState, action) => {
    switch (action.type) {
        case FETCH_CART_ITEMS:
            return {
                ...state,
                cart: action.cart || state.cart,
                loading: false,
                loadingAdding: false,
                loadingDeleting: false
            };
        case LOADING_CART:
            return {...state, loading: true};
        case LOADING_ADDING_CART:
            return {...state, loadingAdding: true, item: action.item};
        case LOADING_DELETING_CART:
            return {...state, loadingDeleting: true, item: action.item};
        default:
            return state;
    }
};

export default reducer;
import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import CustomInput from "../../../components/form/custom_input";
import {handleChangeData, showAlert} from "../../../helpers/helper_functions";
import {Link} from "react-router-dom";

class Register extends Component {
    state = {
        errors: {},
        response: null,
        loading: false
    };

    handleSubmit = event => {
        event.preventDefault();
        const {data} = this.state;
        const {history} = this.props;
        if (isNaN(parseInt(data.phone_number))) {
            this.setState({
                errors: {...this.state.errors, phone_number: ["The phone number must be a number"]}
            })
            return;
        }
        data.phone_number = parseInt(data.phone_number);
        this.setState({loading: true, errors: {}});
        makeRequest(POST_REQUEST, "users/register/", data, () => {
            showAlert('success', 'Successful Registration', 'You will now be redirected to the login' +
                ' page. Please check your email for a verification link to verify your account!');
            history.push("/login");
        }, error => {
            this.setState({
                errors: error.response.data,
                loading: false,
            })
        }, () => {
            this.setState({loading: false})
        })
    }

    render() {
        const {loading, errors} = this.state;
        return (
            <div className="row mx-auto justify-content-center my-5 py-5">
                <div className="col-lg-6 col-md-8">
                    <h3 className="text-center">Register</h3>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label>First Name</label>
                            <CustomInput
                                type="text"
                                name="first_name"
                                onChange={e => handleChangeData(e, this)}
                                placeholder="First Name"
                                icon="perm_identity"
                                className="form-control"
                                required
                            />
                            {errors.first_name && <p className="small text-danger">{errors.first_name[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Last Name</label>
                            <CustomInput
                                type="text"
                                name="last_name"
                                onChange={e => handleChangeData(e, this)}
                                placeholder="Last Name"
                                icon="perm_identity"
                                className="form-control"
                                required
                            />
                            {errors.last_name && <p className="small text-danger">{errors.last_name[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Email:</label>
                            <CustomInput
                                type="email"
                                name="email"
                                onChange={e => handleChangeData(e, this)}
                                placeholder="Email"
                                icon="mail_outline"
                                className="form-control"
                                required
                            />
                            {errors.email && <p className="small text-danger">{errors.email[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Phone Number:</label>
                            <CustomInput
                                type="number"
                                name="phone_number"
                                onChange={e => handleChangeData(e, this)}
                                placeholder="Phone Number"
                                icon="phone"
                                className="form-control"
                                required
                            />
                            {errors.phone_number && <p className="small text-danger">{errors.phone_number[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Password:</label>
                            <CustomInput
                                type="password"
                                name="password_confirmation"
                                placeholder="Password"
                                icon="lock_open"
                                onChange={e => handleChangeData(e, this)}
                                className="form-control"
                                required
                            />
                            {errors.password && <p className="small text-danger">{errors.password[0]}</p>}
                        </div>
                        <div className="form-group">
                            <label>Confirm Password:</label>
                            <CustomInput
                                type="password"
                                name="password"
                                placeholder="Confirm Password"
                                icon="lock_open"
                                onChange={e => handleChangeData(e, this)}
                                className="form-control"
                                required
                            />
                            {errors.password && <p className="small text-danger">{errors.password[0]}</p>}
                        </div>

                        <div className="d-flex justify-content-end my-2">
                            <Link to="/login" className="a-border text-dark">Already have an account? Login</Link>
                        </div>

                        <button
                            disabled={loading}
                            className="btn btn-dark w-100">
                            {loading ? "Loading..." : "Register"}
                        </button>

                    </form>
                </div>
            </div>

        )
    }
}

export default Register;
import React from "react";
import {getUserObject, setUserObject} from "../../../helpers/login";
import {handleChangeData, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import {makeRequest} from "../../../helpers/network_utils";
import {PATCH_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";

const $ = window.$;

class EditInfo extends React.Component {

    state = {
        errors: {},
        data: getUserObject()
    };

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            loading: true,
            errors: {}
        });
        const {data} = this.state;
        const {callback} = this.props;
        makeRequest(PATCH_REQUEST, endpoints.me, data, () => {
            setUserObject(null, () => {
                showAlert('success', 'Success!', 'Details edited successfully!');
                $("#editInfoModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
                if (callback) callback();
            })
        }, error => {
            if (error.response.status === 400)
                this.setState({
                    errors: error.response.data
                })
            else
                showAlert('error', 'Error', parseErrorResponse(error))
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {loading, errors, data} = this.state;
        return (
            <div className="modal fade" id="editInfoModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Edit Phone Number</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>First Name</label>
                                <input name="first_name" type="text" className="form-control"
                                       value={data.first_name} onChange={e => handleChangeData(e, this)}
                                />
                                {errors.first_name && <p className="text-danger small">{errors.first_name}</p>}
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input name="last_name" type="text" className="form-control"
                                       value={data.last_name} onChange={e => handleChangeData(e, this)}
                                />
                                {errors.last_name && <p className="text-danger small">{errors.last_name}</p>}
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input name="email" type="text" className="form-control"
                                       value={data.email} onChange={e => handleChangeData(e, this)}
                                />
                                {errors.email && <p className="text-danger small">{errors.email}</p>}
                            </div>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input name="phone_number" type="number" className="form-control"
                                       value={data.phone_number} onChange={e => handleChangeData(e, this)}
                                />
                                {errors.phone_number && <p className="text-danger small">{errors.phone_number}</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} disabled={loading} type="button"
                                    className="btn btn-primary">{loading ? "Loading.." : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditInfo;
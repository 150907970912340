import React, {Component} from 'react';
import SingleBlog from "./single_blog";
import {onMount} from "../../providers/loader";
import endpoints from "../../routes/endpoints";
import {CircularProgress} from "@material-ui/core";

class Blog extends Component {
    state = {
        blogs: []
    }

    componentDidMount() {
        this.getBlogs();
    }

    getBlogs = () => {
        onMount(this, endpoints.all_blogs, "blogs");
    }

    render() {
        const {blogs, loading} = this.state;
        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                blogs.length > 0 ?
                    <div className="my-5 text-center">
                        <h3 className="mb-5">Blog Posts.</h3>
                        <div className="container">
                            <div className="row mx-auto">
                                {
                                    blogs.map((blog, index) => <SingleBlog key={blog?.id} isMain={index === 0} blog={blog}/>)
                                }
                            </div>
                        </div>
                    </div> : <div className="my-5 text-center">
                        <h5>New Blog Posts Coming Soon!</h5>
                    </div>
        );
    }
}

export default Blog;
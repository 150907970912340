import React, {Component} from 'react';
import SingleOrder from "./single_order";
import {onMount} from "../../providers/loader";
import endpoints from "../../routes/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";

class OrdersList extends Component {

    state = {
        loading: false,
        orders: []
    }

    componentDidMount() {
        onMount(this, endpoints.orders, "orders");
    }

    render() {
        const {loading, orders, loadingNext} = this.state;
        return (
            <div className="container">
                <div className="row mx-auto justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        <div className="d-flex flex-wrap">
                            <h3 className="my-5 a-border-half"> My Orders</h3>
                        </div>
                        {
                            loading ?
                                <div className="text-center my-5">
                                    <CircularProgress/>
                                </div> :
                                orders.map(order => <SingleOrder key={order.id} order={order}/>)
                        }
                        {
                            loadingNext ?
                                <div className="text-center my-5">
                                    <CircularProgress/>
                                </div> : null
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default OrdersList;
import React, {useEffect} from 'react';

import ScrollToTop from "./components/main/scroll_to_top";
import Main from "./components/main";
import {BrowserRouter as Router} from "react-router-dom";
import store from "./redux/store";
import {Provider} from "react-redux";
import './assets/scss/main.scss';
import {showAlert} from "./helpers/helper_functions";
import {RATES} from "./values/globals";

function App() {

    useEffect(() => {
        console.log("Running App.js useEffect");
        setUpRates().then();
    })

    return (
        <Provider store={store}>
            <Router>
                <ScrollToTop>
                    <Main/>
                </ScrollToTop>
            </Router>
        </Provider>
    );
}

const setUpRates = async () => {
    try {
        let response = await fetch("https://api.currencyfreaks.com/latest?apikey=2389c6227b654fd4bcab7cf139d2b9e9");
        const rates = await response.json();
        localStorage.setItem(RATES, rates.rates.KES);
    } catch (e) {
        showAlert('error', 'Error', 'An error occurred. Please try again.');
    }
}

export default App;

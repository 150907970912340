import React, {Component} from 'react';
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import {parseErrorResponse} from "../../../helpers/helper_functions";
import {setUserObject} from "../../../helpers/login";
import CustomInput from "../../../components/form/custom_input";
import Swal from "sweetalert2";
import endpoints from "../../../routes/endpoints";

class Login extends Component {
    state = {
        errors: {},
        response: null,
        loading: false
    };

    handleSubmit = event => {
        event.preventDefault();
        const {email, password} = this.state;

        this.setState({loading: true, errors: {}});
        const data = {email, password};

        makeRequest(POST_REQUEST, endpoints.login, data, () => {
            setUserObject(
                () => ({}),
                () => {
                    this.setState({
                        loading: false
                    });
                    window.location.href = "/";
                });
        }, error => {

            this.setState({
                errors: error.response ? error.response.data : {message: "Could not connect to server. Please try again."},
                loading: false,
            })
        }, () => {
            // this.setState({loading: false})
        })
    }

    resetPasswordDialog = () => {
        Swal.fire({
            title: 'Enter your email',
            input: 'email',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Reset Password',
            confirmButtonColor: '#343a40',
            showLoaderOnConfirm: true,
            preConfirm: (email) => {
                Swal.fire({
                    title: 'Sending Password Reset Link',
                    didOpen: () => {
                        Swal.showLoading();
                    }
                }).then();
                makeRequest(POST_REQUEST, endpoints.password_reset, {email: email}, () => {
                    Swal.fire(
                        'Success!',
                        'A password reset link has been sent to your email.',
                        'success'
                    ).then(() => {
                        return true;
                    });
                }, (error) => {
                    Swal.fire(
                        `Error`,
                        `Request failed: ${parseErrorResponse(error)}`,
                        'error'
                    ).then();
                    return true;
                })
                return false;
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(() => {
            Swal.showValidationMessage(
                `Request failed: `
            );
        });
    }

    render() {
        const {loading, errors} = this.state;
        return (
            <div className="row mx-auto justify-content-center my-5 py-5">
                <div className="col-lg-6 col-md-8">
                    <h3 className="text-center">Login</h3>
                    <form onSubmit={this.handleSubmit}>
                        {errors.message && (
                            <div className="alert alert-danger">
                                {parseErrorResponse(errors.message)}
                            </div>
                        )}
                        <div className="form-group">
                            <label>Email:</label>
                            <CustomInput
                                type="email"
                                onChange={event => this.setState({email: event.target.value})}
                                placeholder="Email"
                                icon="mail_outline"
                                className="form-control"
                                required
                            />
                            {errors.email && <p className="small text-danger">
                                {errors.email[0]}
                            </p>}
                        </div>
                        <div className="form-group">
                            <label>Password:</label>
                            <CustomInput
                                type="password"
                                placeholder="Password"
                                icon="lock_open"
                                onChange={event => this.setState({password: event.target.value})}
                                className="form-control"
                                required
                            />
                            {errors.password && <p className="small text-danger">
                                {errors.password[0]}
                            </p>}
                        </div>

                        <div className="d-flex justify-content-end my-2">
                            <a href="#forgot" className="a-border text-dark" onClick={(e) => {
                                e.preventDefault();

                                //Show popup that will allow the user to input email and send verification request.
                                this.resetPasswordDialog();
                            }}>Forgot password?</a>
                        </div>

                        <button
                            disabled={loading}
                            className="btn btn-dark w-100">
                            {loading ? "Loading..." : "Log In"}
                        </button>

                    </form>
                </div>
            </div>

        )
    }
}

export default Login;
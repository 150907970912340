import React from "react";
import {getUserObject} from "../../../helpers/login";
import EditPaymentModal from "./edit_payment_modal";
import {paymentMethods} from "../../../providers/payment_types/data";

class PaymentInformation extends React.Component {
    state = {};
    render() {
        const user = getUserObject();
        return (
            <div className="row mx-auto">
                <EditPaymentModal callback={() => this.setState({})}/>
                <div className="col-md-4">
                    <h5>Payment Method</h5>
                </div>
                <div className="col-md-8">
                    <div className="d-flex align-items-center">
                        M-Pesa
                        {
                            user.payment_type === paymentMethods.mpesa &&
                            <i className="icon-check text-success ml-3"/>
                        }
                    </div>
                    <div className="d-flex align-items-center">
                        PayPal / Debit Card / Credit Card
                        {
                            user.payment_type === paymentMethods.paypal &&
                            <i className="icon-check text-success ml-3"/>
                        }
                    </div>
                    <div className="d-flex justify-content-end">
                        <button data-toggle="modal"
                                data-target="#editPaymentModal"
                                className="btn btn-sm btn-info">
                            Edit
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentInformation;
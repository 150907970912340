import React, {Component} from 'react';
import {getCartItems} from "../../redux/cart/actions";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";

const paypal = window.paypal;

const mapStateToProps = state => (state.cartItemsState);

const mapDispatchToProps = dispatch => ({
    getCartItems: () => dispatch(getCartItems()),
})

class Paypal extends Component {
    state = {};

    componentDidMount() {
        paypal.Buttons({
            createOrder: this.handleButtonClick,
            onApprove: this.handlePaymentApproval
        }).render('#paypal-button-container');
    }

    handleButtonClick = async (data, actions) => {
        //set loading, get currency rates.
        this.setState({loading: true});
        let total = null;
        try {
            let response = await fetch("https://api.currencyfreaks.com/latest?apikey=2389c6227b654fd4bcab7cf139d2b9e9");
            if (response.status !== 200) {
                showAlert('error', 'Error', 'An error occurred. Please try again.');
            }
            const {cart} = this.props;
            const rates = await response.json();
            total = (cart.total / rates.rates.KES).toFixed(2);
        } catch (e) {
            showAlert('error', 'Error', 'An error occurred. Please try again.');
        }

        this.setState({loading: false})

        return actions.order.create({
            purchase_units: [{
                amount: {
                    currency_code: 'USD',
                    value: total
                }
            }]
        });
    }

    handlePaymentApproval = (data, actions) => {
        // This function captures the funds from the transaction.
        return actions.order.capture().then((details) => {
            this.setState({
                loading: true
            })
            makeRequest(GET_REQUEST, `${endpoints.paypal_approve}${details.id}`, {}, response => {
                const order = response.data;
                const {getCartItems, history} = this.props;
                showAlert('success', 'Success!', 'Your transaction completed successfully');
                getCartItems();
                history.push(`/orders/${order.id}`)
            }, error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                this.setState({
                    loading: false
                })
            })
        });
    }

    render() {
        const {loading} = this.state;
        return (
            <div className="row justify-content-center my-5 py-5">
                <div className="col-lg-8 col-md-10 text-center">
                    <h3 className="mb-5">Choose Payment</h3>
                    <div id="paypal-button-container"/>
                    {
                        loading &&
                        <div className="my-5 text-center">
                            <CircularProgress/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Paypal);
import React from "react";
import notFound from '../../assets/img/404.png';
import {Link} from "react-router-dom";

function NotFound() {
    return (
        <div className="error-position">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center ">
                        <img className="img-fluid" src={notFound} srcSet={notFound} alt=""/>
                        <p
                            className="text-muted f18 mt-4 mb-5">Ooops! Something went wrong .Go back to the Earth.</p>
                        <Link to="/" className="btn btn-dark">Back to Home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;
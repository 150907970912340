import React, {Component} from 'react';
import {getQueryParamsFromString, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import CustomInput from "../../../components/form/custom_input";
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";

class PasswordReset extends Component {
    state = {
        errors: {},
        response: null,
        loading: false
    };

    handleSubmit = e => {
        e.preventDefault();
        const {password_confirmation, password} = this.state;
        const {token} = this.props.match.params;

        const {location} = this.props;
        const queryObject = getQueryParamsFromString(location.search);

        this.setState({loading: true, errors: {}});
        const data = {password_confirmation, password, token, email: queryObject.email};

        makeRequest(POST_REQUEST, endpoints.reset_password, data, () => {
            showAlert('success', 'Success', 'Password reset successfully!',()=>{
                window.location.href = "/login";
            })

        }, error => {

            this.setState({
                errors: error.response ? error.response.data : {message: "Could not connect to server. Please try again."},
                loading: false,
            })
        }, () => {
            // this.setState({loading: false})
        })
    }


    render() {
        const {loading, errors} = this.state;
        return (
            <div className="row mx-auto justify-content-center my-5 py-5">
                <div className="col-lg-6 col-md-8">
                    <h3 className="text-center">Reset Password</h3>
                    <form onSubmit={this.handleSubmit}>
                        {errors.message && (
                            <div className="alert alert-danger">
                                {parseErrorResponse(errors.message)}
                            </div>
                        )}
                        {errors.token && (
                            <div className="alert alert-danger">
                                {parseErrorResponse(errors.token)}
                            </div>
                        )}
                        {errors.email && (
                            <div className="alert alert-danger">
                                {parseErrorResponse(errors.email)}
                            </div>
                        )}
                        <div className="form-group">
                            <label>Password:</label>
                            <CustomInput
                                type="password"
                                placeholder="Password"
                                icon="lock_open"
                                onChange={event => this.setState({password: event.target.value})}
                                className="form-control"
                                required
                            />
                            {errors.password && <p className="small text-danger">
                                {errors.password[0]}
                            </p>}
                        </div>
                        <div className="form-group">
                            <label>Confirm Password:</label>
                            <CustomInput
                                type="password"
                                onChange={event => this.setState({password_confirmation: event.target.value})}
                                placeholder="Password"
                                icon="lock_open"
                                className="form-control"
                                required
                            />
                            {errors.password && <p className="small text-danger">
                                {errors.password[0]}
                            </p>}
                        </div>
                        <button
                            disabled={loading}
                            className="btn btn-dark w-100">
                            {loading ? "Loading..." : "Reset"}
                        </button>
                    </form>
                </div>
            </div>

        )
    }
}

export default PasswordReset;
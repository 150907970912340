import React from "react";
import {Link} from "react-router-dom";
import {numberWithCommas} from "../../helpers/helper_functions";

function SingleOrder(props) {
    const {order} = props;
    return (
        <div className="card rounded shadow-sm my-3">
            <div className="card-body">
                <div className="col-12">
                    <h3 className="my-3">{order.reference_code}</h3>
                    <h5 className="my-3">KES {numberWithCommas(order.total || "-")}</h5>
                    <div className="d-flex justify-content-between">
                        <p
                            className={`${order.status === "pending" ? "badge-info" : "badge-success"}
                             px-3 py-0 rounded-pill small d-flex align-items-center`}>
                            {order.status?.toUpperCase() || "-"}
                        </p>
                        <Link to={`/orders/${order.id}`} className="btn btn-primary">
                            View
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleOrder;
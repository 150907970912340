import React, {Component} from 'react';
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {CircularProgress} from "@material-ui/core";
import notFound from "../../assets/img/404.png";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import SingleBlog from "./single_blog";
import HTMLReactParser from "html-react-parser";

class BlogDetails extends Component {

    state = {
        blogs: []
    }

    componentDidMount() {
        this.getBlog();
        this.getOtherBlogs();
    }

    getBlog = () => {
        const {id} = this.props.match.params;
        this.setState({
            loading: true
        });
        makeRequest(
            GET_REQUEST,
            `${endpoints.all_blogs}${id}`,
            {},
            response => {
                this.setState({
                    blog: response.data,
                })
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                this.setState({
                    loading: false
                })
            });
    }

    getOtherBlogs = () => {
        makeRequest(GET_REQUEST, endpoints.all_blogs, {}, response => {
            this.setState({
                blogs: response.data.data.slice(0, 4)
            })
        })
    }

    render() {
        const {loading, blog, blogs} = this.state;
        return (
            <div className="my-5">
                {loading ?
                    <div className="text-center">
                        <CircularProgress/>
                    </div> :
                    blog ?
                        <div className="container-fluid">
                            <div className="row mx-auto">
                                <div className="col-md-9 p-4">
                                    <h2 className="text-left">
                                        {blog.title}
                                    </h2>
                                    <div style={{height: "60vh"}}>
                                        <img style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "contain"
                                        }}
                                             src={blog.cover_image || notFound}
                                             alt={"blog placeholder"}/>
                                    </div>
                                    <h4 className="my-3 pl-4"> {blog.description}</h4>
                                    <div style={{overflow: "hidden"}} className="w-100">
                                        {HTMLReactParser(blog?.body || "")}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="row mx-auto">
                                        {
                                            blogs.length > 0 ?
                                                blogs.map(blog => <SingleBlog isAddition={true} key={blog.id}
                                                                              blog={blog}/>)
                                                :
                                                <h5>More Blogs Posts Coming Soon!</h5>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div className="text-center">
                            <p>Blog could not be loaded.</p>
                        </div>
                }
            </div>

        );
    }

}

export default BlogDetails;
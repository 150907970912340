import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_BOOK_TAGS = "reload_book_tags";

export const LOADING_BOOK_TAGS = "loading_book_tags";

export const getBookTags = () => {
    return dispatch => {
        dispatch({
            type: LOADING_BOOK_TAGS,
        });

        makeRequest(
            GET_REQUEST,
            `${endpoints.book_tags}`,
            {},
            response => {
                dispatch({
                    book_tags: response.data,
                    type: FETCH_BOOK_TAGS
                })
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
                dispatch({
                    type: FETCH_BOOK_TAGS
                })
            },);
    }
}

import React from "react";
import NavBar from "./navbar";
import {Route, Switch, useHistory} from "react-router";
import {routes} from '../../routes/routes';
import NotFound from "./404";
import BookDetails from "../../pages/books/details";
import CartItems from "../../pages/cart";
import Footer from "./footer";

function Main() {
    const history = useHistory();
    return (
        <div>
            <BookDetails/>
            <CartItems history={history}/>
            <NavBar/>
            <div className="min-vh-100">
                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            children={({location, history, match}) =>
                                <route.component location={location}
                                                 history={history}
                                                 match={match}/>
                            }
                        />
                    ))}
                    <NotFound/>
                </Switch>
            </div>
            <Footer/>
        </div>
    )
}

export default Main;
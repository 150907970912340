import React, {useState} from 'react';
import {Document} from 'react-pdf/dist/esm/entry.webpack';
import {Page} from 'react-pdf';
import endpoints from "../../../routes/endpoints";
import {useParams} from "react-router";

function PdfViewer() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const {book, order} = useParams();

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    function printDocument(documentId) {
        const doc = document.getElementById(documentId);

        //Wait until PDF is ready to print
        if (typeof doc.print === 'undefined') {
            setTimeout(function () {
                printDocument(documentId);
            }, 1000);
        } else {
            doc.print();
        }
    }

    const endpoint = `https://eastafricansafarirally.com/api/${endpoints.download_book}?book=${book}&order=${order}`;
    const endpointObj = {url: endpoint, withCredentials: true}
    return (
        <div>
            <iframe title={"pdf"} style={{display: "none"}}>
                <embed
                    type="application/pdf"
                    src={endpoint}
                    id="pdfDocument"
                    width="100%"
                    height="100%"/>
            </iframe>
            <div className="container pdf-container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div style={{height: "70vh", width: "100%", overflow: "auto"}} className="d-flex justify-content-center">
                            <Document
                                onContextMenu={(e) => e.preventDefault()}
                                file={endpointObj}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber}/>
                            </Document>
                        </div>

                        <p>Page {pageNumber} of {numPages}</p>
                        <div className="d-flex justify-content-center mb-3">
                            <button onClick={() => {
                                if (pageNumber > 1)
                                    setPageNumber(pageNumber - 1);
                            }} className="btn btn-primary btn-sm">
                                Previous
                            </button>
                            <div className="mx-3"/>
                            <button onClick={() => {
                                printDocument("pdfDocument");
                            }} className="btn btn-primary btn-sm">
                                Print
                            </button>
                            <div className="mx-3"/>
                            <button onClick={() => {
                                if (pageNumber < numPages)
                                    setPageNumber(pageNumber + 1);
                            }} className="btn btn-primary btn-sm">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PdfViewer;
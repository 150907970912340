import React, {Component} from 'react';
import homeBg from '../../assets/img/home_bg.jpg';
import CategoriesSidebar from "../../components/main/categories_sidebar";
import SingleBook from "../books/single_book";
import CircularProgress from "@material-ui/core/CircularProgress";
import {onMount} from "../../providers/loader";
import endpoints from "../../routes/endpoints";
import {search} from "../../providers/search/loader";
import SearchBar from "../../components/main/search_bar";

class Home extends Component {

    state = {
        loading: false,
        loadingNext: false,
        books: [],
    }

    componentDidMount() {
        onMount(this, endpoints.all_books);
    }

    searchAction = (value) => {
        search(`${endpoints.all_books}`, value, this, "books", true)
    }

    render() {
        let {loading, loadingNext, books, loadingSearch} = this.state;
        return (
            <div>
                <div style={{
                    backgroundImage: `url(${homeBg})`,
                    backgroundSize: "cover",
                    position: "relative",
                    height: "70vh",
                    marginBottom: "5rem"
                }} className="row mx-auto justify-content-center align-items-center">
                    <div className="overlay"/>
                    <div className="col-md-6 mt-5 px-5">
                        <h1 className="text-center display-5 text-white">
                            Ebook Store.
                        </h1>
                    </div>
                </div>

                <div className="container-fluid my-5">
                    <div className="row mx-auto">
                        <CategoriesSidebar/>
                        <div className="col-md-8">
                            <div className="row justify-content-center">
                                <div className="col-md-10">
                                    <SearchBar loadingSearch={loadingSearch} searchAction={this.searchAction}/>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center flex-wrap my-3">
                                <h3 className="a-border-half font-weight-normal">
                                    Featured Products
                                </h3>
                            </div>
                            <div className="row mx-auto">
                                {
                                    loading ?
                                        <div className="text-center mx-auto my-5">
                                            <CircularProgress/>
                                        </div> :
                                        books.length < 1 ?
                                            <h5 className="mx-auto">
                                                No content uploaded yet.
                                            </h5> :
                                            books.map(book => <SingleBook key={book.id} book={book}/>)
                                }
                                {
                                    loadingNext ?
                                        <div className="text-center mx-auto my-3">
                                            <CircularProgress/>
                                        </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Home;
import React, {Component} from 'react';
import OrderItem from "./order_item";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import CircularProgress from "@material-ui/core/CircularProgress";

class OrderDetails extends Component {

    state = {
        order: null,
        loading: false
    }

    componentDidMount() {
        this.getOrder();
    }

    getOrder = () => {
        const {id} = this.props.match.params;
        this.setState({loading: true});
        makeRequest(GET_REQUEST, `${endpoints.orders}${id}`, {}, response => {
            this.setState({
                order: response.data
            })
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {loading, order} = this.state;
        return (
            loading ?
                <div className="text-center my-5">
                    <CircularProgress/>
                </div> :
                order ? <div className="container">
                    <div className="col-12">
                        <div className="d-flex flex-wrap mt-5">
                            <h2 className="a-border-half">{order.reference_code}</h2>
                        </div>
                        <h5>KES {order.total}</h5>
                        <div className="d-flex justify-content-between">
                            <p
                                className={`${order.status === "pending" ? "badge-info" : "badge-success"}
                             px-3 py-0 rounded-pill small d-flex align-items-center`}>
                                {order.status?.toUpperCase() || "-"}
                            </p>
                        </div>

                        <div className="d-flex flex-wrap">
                            <h3 className="a-border-half my-3">Items</h3>
                        </div>
                        {
                            order.items.map(item => <OrderItem orderId={order.id} item={item}/>)
                        }
                    </div>
                </div> : <h5 className="my-5 text-center">
                    The order could not be loaded.
                </h5>
        );
    }
}

export default OrderDetails;
import * as PropTypes from "prop-types";
import notFound from "../../assets/img/404.png";

function SingleBlog({blog, isMain, isAddition}) {
    return (
        isMain ?
            <div className="col-12 mb-4">
                <div className="card w-100 shadow-sm">
                    <div className="row align-items-center no-gutters">
                        <div className="col-md-5">
                            <img className="card-img" src={blog.cover_image || notFound}
                                 style={{height: "20rem", objectFit: "cover",width: "100%"}}
                                 alt="blog"/>
                        </div>
                        <div className="col-md-7">
                            <div className="card-body text-left ml-4">
                                <h4 className="card-title">{blog.title}</h4>
                                <p className="card-text">{blog.description}</p>
                                <a href={`/blog/${blog.slug}`} className="btn btn-dark">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
            <div className={`${isAddition ? "col-12 mb-3" : "col-md-6 mb-4"}`}>
                <div className="card w-100 shadow-sm">
                    {isAddition ? null : <img src={blog.cover_image || notFound} className="card-img-top"
                                              style={{height: "10rem", objectFit: "cover"}}
                                              alt="..."/>}
                    <div className="card-body">
                        <h4 className="card-title">{blog.title}</h4>
                        <p className="card-text">{blog.description}</p>
                        <a href={`/blog/${blog.slug}`} className="btn btn-dark">Read More</a>
                    </div>
                </div>
            </div>
    )
}

SingleBlog.propTypes = {
    blog: PropTypes.object,
    isMain: PropTypes.bool,
    isAddition: PropTypes.bool,
}
export default SingleBlog;
import React, {Component} from 'react';
import UserInformation from "./user_information";
import PaymentInformation from "./payment_information";
import InformationEdit from "./edit_information";

class Profile extends Component {
    render() {
        return (
            <div className="container px-5 py-5 my-3">
                <h3 className="a-border-full font-weight-normal mb-5">
                    User Profile
                </h3>
                <UserInformation/>
                <hr className="my-5"/>
                <PaymentInformation/>
                <hr className="my-5"/>
                <InformationEdit/>
            </div>
        );
    }
}

export default Profile;
import {withRouter} from "react-router";
import {Component} from "react";

class ScrollToTop extends Component {
    componentDidUpdate(prevProps, _, __) {
        window.scrollTo(0, 0)
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)
const endpoints = {
    login: "login/",
    all_books: "books/",
    all_blogs: "blogs/",
    document_books: "document-books/",
    audio_books: "audio-books/",
    video_books: "video-books/",
    book_tags: "book-tags/",
    orders: "users/orders/",
    current_order: "users/orders/current/",
    me: "me/",
    logout: "logout/",
    csrf_cookie: "sanctum/csrf-cookie/",
    checkout: "users/checkout",
    paypal_approve: "paypal/approve/",
    download_book: "users/books/download/",
    validate_mpesa: "mpesa/stk-push/validate/",
    password_reset: "forgot",
    reset_password: "password/reset/",
    change_password: "password/change/"
};

export default endpoints;
import React, {Component} from 'react';
import {Link, useLocation} from "react-router-dom";

class CategoriesSidebar extends Component {
    render() {
        return (
            <div className="col-md-3 d-none d-md-block">
                <h3 className="a-border-half font-weight-normal mb-3">
                    Categories
                </h3>

                <NavItem to={"/"} text={"All Products"}/><br/>
                <NavItem to={"/books"} text={"Books"}/><br/>
                <NavItem to={"/podcasts"} text={"Podcasts"}/><br/>
                <NavItem to={"/webinar"} text={"Online Courses"}/><br/>
            </div>
        );
    }
}

function NavItem(props) {
    const {to, text} = props;
    const location = useLocation();
    const shouldBeActive = location.pathname === props.to;
    return (
        <div data-toggle="collapse" data-target=".navbar-collapse.show"
             className={`a-border px-0 ${shouldBeActive ? "a-border-active" : ""}`}>
            <Link className="text-dark" to={to}>{text}</Link>
        </div>
    )
}

export default CategoriesSidebar;
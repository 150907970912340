import React from "react";
import {makeRequest} from "../../../helpers/network_utils";
import {PATCH_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {setUserObject} from "../../../helpers/login";
import {handleChange, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";

const $ = window.$;

class EditPaymentModal extends React.Component {

    state = {
        method: "",
        errors: {}
    };

    handlePaymentMethodChange = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {method} = this.state;
        const {callback} = this.props;
        makeRequest(PATCH_REQUEST, endpoints.me, {payment_type: method}, () => {
            setUserObject(null, () => {
                if (callback) callback();
            })
            showAlert('success', 'Success!', 'Payment method edited successfully!');
            $("#editPaymentModal").modal('toggle');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
        }, error => {
            if (error.response.status === 400)
                this.setState({
                    errors: error.response.data
                })
            else
                showAlert('error', 'Error!', parseErrorResponse(error));
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {loading} = this.state;
        return (
            <div className="modal fade" id="editPaymentModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Edit Payment Method</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Select Payment Method</label>
                                <select name="method" onChange={e => handleChange(e, this)}
                                        className="form-control">
                                    <option>Select Payment Method</option>
                                    <option value="mpesa">Mpesa</option>
                                    <option value="paypal">Paypal</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handlePaymentMethodChange}
                                    disabled={loading}
                                    type="button"
                                    className="btn btn-primary">
                                {loading ? 'Loading' : 'Save'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditPaymentModal;
import React from "react";
import {getUserObject} from "../../../helpers/login";
import EditInfo from "./edit_info";

class UserInformation extends React.Component {
    render() {
        const user = getUserObject();
        return (
            <div className="row mx-auto">
                <EditInfo callback={() => this.setState({})}/>
                <div className="col-md-4">
                    <h5>User Information</h5>
                </div>
                <div className="col-md-8">
                    <SingleRow title={"First Name"} value={user.first_name}/>
                    <SingleRow title={"Last Name"} value={user.last_name}/>
                    <SingleRow title={"Email"} value={user.email}/>
                    <SingleRow title={"Phone Number"} value={user.phone_number}/>
                    <div className="d-flex justify-content-end">
                        <button data-toggle="modal"
                                data-target="#editInfoModal"
                                className="btn btn-sm btn-info">
                            Edit
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function SingleRow(props) {
    return (
        <div className="flex-column">
            <p className="font-weight-bold m-0"> {props.title} </p>
            <p> {props.value} </p>
        </div>
    )
}

export default UserInformation;
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_BOOK_DETAILS = "reload_book_details";

export const LOADING_BOOK_DETAILS = "loading_book_details";

export const getBookDetails = (book) => {
    return dispatch => {
        dispatch({
            type: LOADING_BOOK_DETAILS,
            book: book
        });

        makeRequest(
            GET_REQUEST,
            `${endpoints.all_books}${book.slug}`,
            {},
            response => {
                dispatch({
                    book: response.data,
                    type: FETCH_BOOK_DETAILS
                })
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
                dispatch({
                    type: FETCH_BOOK_DETAILS
                })
            },);
    }
}

import React, {Component} from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {changeItemQuantity, getCartItems, removeItemFromCart} from "../../redux/cart/actions";
import notFound from "../../assets/img/404.png";
import debounce from "@material-ui/core/utils/debounce";
import {makeRequest} from "../../helpers/network_utils";
import {POST_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import Swal from "sweetalert2";
import {numberWithCommas, parseErrorResponse, showAlert, toTitleCase} from "../../helpers/helper_functions";
import {getUserObject} from "../../helpers/login";
import {paymentMethods} from "../../providers/payment_types/data";

const mapStateToProps = state => (state.cartItemsState);
const $ = window.$;
const mapDispatchToProps = dispatch => ({
    getCartItems: () => dispatch(getCartItems()),
    removeItemFromCart: item => dispatch(removeItemFromCart(item)),
    changeItemQuantity: (item, quantity) => dispatch(changeItemQuantity(item, quantity))
})

class CartItems extends Component {

    state = {
        loadingCheckout: false
    }

    componentDidMount() {
        const {cart, getCartItems} = this.props;
        if (cart == null)
            getCartItems();
    }

    handleQuantityChange = debounce((e, item) => {
        const {changeItemQuantity} = this.props;
        changeItemQuantity(item, e.target.value)
    }, 1000)

    handleCheckout = () => {
        const {history} = this.props;
        const user = getUserObject();
        Swal.fire({
            title: `Confirm Payment Method`,
            html: `<p><b>${toTitleCase(user.payment_type)}</b> will be used to complete this payment.
            If you wish to change this, press change, otherwise just click confirm.</p>`,
            showCancelButton: true,
            footer: '<a href="/profile">Change Payment Method</a>',
            confirmButtonText: 'Confirm',
            allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
            if (result.isDismissed) return;
            if (user.payment_type === paymentMethods.paypal) {
                //Redirect to paypal page.
                $("#cartItemsModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();

                history.push('/paypal');
            } else {
                this.setState({
                    loadingCheckout: true
                })
                makeRequest(POST_REQUEST, endpoints.checkout, {}, response => {
                    const res = response.data;
                    const {history, cart} = this.props;
                    Swal.fire({
                        title: `Mpesa Payment Confirmation for KES ${numberWithCommas(cart?.total || "-")}`,
                        text: `Please check your mobile phone for a prompt and click "Confirm" after submitting.`,
                        showCancelButton: true,
                        confirmButtonText: 'Confirm',
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then(result => {
                        if (result.isDismissed) return;
                        Swal.fire({
                            didOpen: () => {
                                Swal.showLoading()
                            },
                            title: 'Validating transaction',
                            text: 'Validating Transaction',
                            showCancelButton: false,
                            showConfirmButton: false,
                            allowOutsideClick: () => !Swal.isLoading()
                        }).then();
                        makeRequest(POST_REQUEST, endpoints.validate_mpesa, {
                            request_id: res.CheckoutRequestID
                        }, response => {
                            const order = response.data;
                            showAlert('success', 'Success!', 'Your transaction completed successfully');
                            $("#cartItemsModal").modal('toggle');
                            $('body').removeClass('modal-open');
                            $('.modal-backdrop').remove();
                            this.props.getCartItems();
                            history.push(`/orders/${order.id}`)
                        }, error => {
                            showAlert('error', 'Error!', parseErrorResponse(error));
                        }, () => {
                            Swal.hideLoading();
                        })
                    })
                }, error => {
                    showAlert('error', 'Error', parseErrorResponse(error))
                }, () => {
                    this.setState({loadingCheckout: false})
                })
            }
        });
    }

    render() {
        let {cart, loading, loadingAdding, item, loadingDeleting, removeItemFromCart} = this.props;
        // console.log(loading);
        const {loadingCheckout} = this.state;
        if (cart == null || cart?.length < 1)
            cart = {items: []};

        return (
            <div className="modal fade" id="cartItemsModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">Shopping Cart</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {
                            loading ?
                                <div className="text-center my-5 py-5">
                                    <CircularProgress/>
                                </div> :
                                <div className="modal-body py-5">
                                    {
                                        cart?.items?.length < 1 ?
                                            <div className="text-center">
                                                <i style={{fontSize: "1.2rem"}} className="icon-basket"/>
                                                <p className="font-weight-bold">
                                                    No items have been added to cart
                                                </p>
                                            </div>
                                            : <div className="col-12">
                                                <table className="table table-striped table-light">
                                                    <thead className="thead-dark">
                                                    <tr>
                                                        <th scope="col">Product</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Quantity</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {cart?.items?.map(cartItem =>
                                                        <tr key={cartItem.id}>
                                                            <td className="d-flex align-items-center">
                                                                <img style={{
                                                                    width: "40%",
                                                                    height: "10vh",
                                                                    objectFit: "contain"
                                                                }}
                                                                     className="mr-3"
                                                                     src={cartItem.book.path || notFound}
                                                                     alt={"book placeholder"}/>
                                                                <p className="flex-grow-1 font-weight-bold">{cartItem.book.title}</p>
                                                            </td>
                                                            <td>KES {cartItem.book.price}</td>
                                                            <td>
                                                                <div
                                                                    className="d-flex flex-column">
                                                                    <input className="form-control mr-3"
                                                                           onChange={e => this.handleQuantityChange(e, cartItem.book)}
                                                                           placeholder={cartItem.quantity}
                                                                           type="number"/>
                                                                    <div
                                                                        className="d-flex justify-content-end mt-2">
                                                                        {
                                                                            loadingDeleting && item.id === cartItem.book.id ?
                                                                                <CircularProgress size={"1rem"}/> :
                                                                                <i onClick={() => removeItemFromCart(cartItem.book)}
                                                                                   style={{cursor: "pointer"}}
                                                                                   className="d-block icon-trash text-danger float-right"/>
                                                                        }
                                                                        {
                                                                            loadingAdding && item.id === cartItem.book.id ?
                                                                                <CircularProgress size={"1rem"}/> : null
                                                                        }
                                                                    </div>


                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </table>
                                                <h5>Total: KES {numberWithCommas(cart?.total || "-")}</h5>
                                                <div className="clearfix">
                                                    <div className="float-right">
                                                        <button disabled={loadingCheckout}
                                                                onClick={this.handleCheckout}
                                                                className="btn btn-dark">
                                                            {loadingCheckout ? "Loading..." : "Checkout"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItems);
import React from "react";
import {handleChangeData, parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import {makeRequest} from "../../../helpers/network_utils";
import {POST_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {setUserObject} from "../../../helpers/login";

const $ = window.$;

class EditPasswordModal extends React.Component {

    state = {
        errors: {},
        data: {}
    };

    handleSubmit = e => {
        e.preventDefault();

        this.setState({
            loading: true,
            errors: {}
        });
        const {data} = this.state;
        makeRequest(POST_REQUEST, endpoints.change_password, data, () => {
            setUserObject(null, () => {
                showAlert('success', 'Success!', 'Password changed successfully!');
                $("#editPasswordModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            })
        }, error => {
            if (error.response.status === 400)
                this.setState({
                    errors: error.response.data
                })
            else
                showAlert('error', 'Error', parseErrorResponse(error))
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {loading, errors} = this.state;
        return (
            <div className="modal fade" id="editPasswordModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Edit Password</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Old Password</label>
                                <input onChange={e => handleChangeData(e, this)} type="password" name="old_password"
                                       className="form-control"/>
                                {errors.old_password && <p className="text-danger small">{errors.old_password}</p>}
                            </div>
                            <div className="form-group">
                                <label>New Password</label>
                                <input onChange={e => handleChangeData(e, this)} type="password" name="password"
                                       className="form-control"/>
                                {errors.password && <p className="text-danger small">{errors.password}</p>}
                            </div>
                            <div className="form-group">
                                <label>Confirm New Password</label>
                                <input onChange={e => handleChangeData(e, this)} type="password"
                                       name="password_confirmation" className="form-control"/>
                                {errors.password_confirmation &&
                                <p className="text-danger small">{errors.password_confirmation}</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} disabled={loading} type="button"
                                    className="btn btn-primary">{loading ? "Loading.." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditPasswordModal;
import React, {Component} from 'react';
import CategoriesSidebar from "../../components/main/categories_sidebar";
import SingleBook from "../books/single_book";
import CircularProgress from "@material-ui/core/CircularProgress";
import {onMount} from "../../providers/loader";
import endpoints from "../../routes/endpoints";

class AudioBooks extends Component {
    state = {
        loading: false,
        loadingNext: false,
        books: [],
    }

    componentDidMount() {
        const {location} = this.props;
        onMount(this, `${endpoints.audio_books}${location.search}`);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldLocation = prevProps.location;
        const newLocation = this.props.location;
        if (newLocation.search !== oldLocation.search)
            onMount(this, `${endpoints.audio_books}${newLocation.search}`);
    }

    render() {
        const {loading, loadingNext, books} = this.state;
        return (
            <div className="container-fluid my-5">
                <div className="row mx-auto">
                    <CategoriesSidebar/>
                    <div className="col-md-8">
                        <div className="d-flex justify-content-center flex-wrap my-3">
                            <h3 className="a-border-half font-weight-normal">
                                Podcasts
                            </h3>
                        </div>
                        <div className="row mx-auto">
                            {
                                loading ?
                                    <div className="text-center mx-auto my-5">
                                        <CircularProgress/>
                                    </div> :
                                    books.length < 1 ?
                                        <h5 className="mx-auto">
                                            No content uploaded yet.
                                        </h5> :
                                        books.map(book => <SingleBook key={book.id} book={book}/>)
                            }
                            {
                                loadingNext ?
                                    <div className="text-center mx-auto my-3">
                                        <CircularProgress/>
                                    </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AudioBooks;
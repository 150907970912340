import React, {Component} from 'react';

class Academy extends Component {
    render() {
        return (
            <div className="my-5 py-5 text-center">
                <h3>Coming Soon.</h3>
            </div>
        );
    }
}

export default Academy;
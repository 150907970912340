import * as PropTypes from "prop-types";
import {Component} from "react";

class SearchBar extends Component {
    state = {
        search_string: ''
    };

    render() {
        let {loadingSearch, searchAction} = this.props;
        return (
            <div className="row mx-auto justify-content-center mb-4">
                <div className="col-lg-8 col-md-10">
                    <div className="form-inline my-2 my-lg-0 flex-grow-1">
                        <div className="input-group mr-sm-2 flex-grow-1 input-main-border my-2">
                            <input onChange={e => {
                                this.setState({
                                    search_string: e.target.value
                                })
                                searchAction(e.target.value)
                            }}
                                   className="form-control border-right-0"
                                   type="search"
                                   placeholder="Search"
                                   aria-label="Search"/>
                            {loadingSearch ? <div className="input-group-append input-main-border-icon">
                                <span className="input-group-text bg-transparent" id="basic-addon1">
                                    <div
                                        className="spinner-border spinner-border-sm text-primary"
                                        role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </span>
                            </div> : null}
                        </div>
                        <button onClick={() => searchAction(this.state.search_string)}
                                className="btn btn-dark my-2 my-sm-0"
                                type="button">Search
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

SearchBar.propTypes = {
    loadingSearch: PropTypes.bool,
    searchAction: PropTypes.func
}

export default SearchBar;

